import React, { useCallback } from "react";
import { User as FirebaseUser } from "firebase/auth";
import { Authenticator, buildCollection, buildProperty, EntityReference, FirebaseCMSApp } from "@camberi/firecms";
import "typeface-rubik";
import "@fontsource/ibm-plex-mono";

export const adminEmails: string[] = ["arturo@magicangel.org", "rosegpeterson@gmail.com"];
export const languageEnumValues = {
  en: "English",
  es: "Español",
};
export const appKeyEnumValues = {
  bible: "bible",
  portfolio: "portfolio",
  maf: "maf",
  nrwl: "nrwl",
  interpegasus: "interpegasus",
  gallery: "gallery",
};
export const getFirebaseConfig = (currentApp: string) => {
  let firebaseConfig: any = null;
  if (currentApp === "Bible") {
    firebaseConfig = {
      apiKey: "AIzaSyCfrD1hJewP_p9xH3_7VZ4FRTwcWugo-hQ",
      authDomain: "bible-love-2.firebaseapp.com",
      projectId: "bible-love-2",
      storageBucket: "bible-love-2.appspot.com",
      messagingSenderId: "756564584441",
      appId: "1:756564584441:web:8265ccc3c9ba46b50ebf28",
      measurementId: "G-DKMW8QSB86",
    };
  } else if (currentApp === "Gallery") {
    firebaseConfig = {
      apiKey: "AIzaSyAuc29wbjjWhzynsfta3jAGQ7yUSS2HZJA",
      authDomain: "arturogallery.firebaseapp.com",
      projectId: "arturogallery",
      storageBucket: "arturogallery.appspot.com",
      messagingSenderId: "441722085337",
      appId: "1:441722085337:web:aceea6464060121f7943c8",
      measurementId: "G-BMDHQHSWJ3",
    };
  } else if (currentApp === "InterPegasus") {
    firebaseConfig = {
      apiKey: "AIzaSyCSi5N4pYruaV6bxN9KxUCDdGxnMptZW-k",
      authDomain: "interpegasus.firebaseapp.com",
      projectId: "interpegasus",
      storageBucket: "interpegasus.appspot.com",
      messagingSenderId: "474474965453",
      appId: "1:474474965453:web:79566feaaf70d5f7723c84",
      measurementId: "G-EXJ2VP2QK1",
    };
  } else if (currentApp === "Portfolio") {
    firebaseConfig = {
      apiKey: "AIzaSyA_T_PJqWBTNQDIG62JWgKTh4br1SUOAAI",
      authDomain: "portfolio-graphql.firebaseapp.com",
      projectId: "portfolio-graphql",
      storageBucket: "portfolio-graphql.appspot.com",
      messagingSenderId: "482963862459",
      appId: "1:482963862459:web:21667680ebbedaef9a4a9e",
      measurementId: "G-LK0LKPWTRG",
    };
  } else if (currentApp === "MAF") {
    firebaseConfig = {
      apiKey: "AIzaSyC5hmQRZDqFoNF8s-DWjKxLyWNws30fkd8",
      authDomain: "magicangel-graphql.firebaseapp.com",
      projectId: "magicangel-graphql",
      storageBucket: "magicangel-graphql.appspot.com",
      messagingSenderId: "435450226774",
      appId: "1:435450226774:web:6d17d648ba5d6e1f78b3f7",
      measurementId: "G-2HVHG0CRF0",
    };
  } else if (currentApp === "Nrwl") {
    firebaseConfig = {
      apiKey: "AIzaSyDgB4hABinre-yJZE9bJ6qDyQDv0usa154",
      authDomain: "nrwl-graphql.firebaseapp.com",
      projectId: "nrwl-graphql",
      storageBucket: "nrwl-graphql.appspot.com",
      messagingSenderId: "18119664277",
      appId: "1:18119664277:web:59172f168376f55b2e319c",
      measurementId: "G-RL95MLD1CN",
    };
  }
  return firebaseConfig;
};

const currentApp = "Gallery";
const firebaseConfig = getFirebaseConfig(currentApp);

type apps = {
  domain: string;
  name: string;
  enabled: boolean;
  facebookKey: string;
  flickrKey: string;
  twitterKey: string;
  maintenanceURL: string;
};

type categories = {
  slug: string;
  languageCode: string;
  name: string;
  parentCategoryId: EntityReference;
  showFooter: boolean;
  showFrontpage: boolean;
  showHeader: boolean;
  enabled: boolean;
  imageURL: string;
};

type pages = {
  slug: string;
  categoryId: EntityReference;
  title: string;
  content: string;
  enabled: boolean;
  imageURL: string;
  tags: EntityReference[];
  links: EntityReference[];
};

type links = {
  url: string;
  name: string;
};

type tags = {
  tag: string;
};

const appsCollection = buildCollection<apps>({
  name: "Apps",
  singularName: "App",
  path: "apps",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  properties: {
    domain: {
      name: "Domain",
      validation: { required: true, unique: true },
      dataType: "string",
    },
    name: {
      name: "Name",
      validation: { required: true, unique: true },
      dataType: "string",
      enumValues: appKeyEnumValues,
    },
    enabled: {
      name: "Enabled",
      validation: { required: false },
      dataType: "boolean",
    },
    facebookKey: {
      name: "facebookKey",
      validation: { required: false, unique: false },
      dataType: "string",
    },
    flickrKey: {
      name: "flickrKey",
      validation: { required: false, unique: false },
      dataType: "string",
    },
    twitterKey: {
      name: "twitterKey",
      validation: { required: false, unique: false },
      dataType: "string",
    },
    maintenanceURL: buildProperty({
      name: "Image",
      dataType: "string",
      storage: {
        storagePath: "maintenance_images",
        acceptedFiles: ["image/*"],
      },
    }),
  },
});

const categoriesCollection = buildCollection<categories>({
  name: "Categories",
  singularName: "Category",
  path: "categories",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  properties: {
    slug: {
      name: "Slug",
      validation: { required: true, unique: true },
      dataType: "string",
    },
    enabled: {
      name: "Enabled",
      validation: { required: false },
      dataType: "boolean",
    },
    languageCode: {
      name: "Language Code",
      validation: { required: true },
      dataType: "string",
      enumValues: languageEnumValues,
    },
    parentCategoryId: buildProperty({
      dataType: "reference",
      path: "categories",
      validation: { required: false },
      name: "Category",
    }),
    name: {
      name: "Name",
      description: "Name",
      dataType: "string",
      validation: { required: true, unique: true },
      columnWidth: 300,
    },
    imageURL: buildProperty({
      name: "Image",
      dataType: "string",
      storage: {
        storagePath: "category_images",
        acceptedFiles: ["image/*"],
      },
    }),
    showFooter: {
      name: "Show Footer",
      validation: { required: false },
      dataType: "boolean",
    },
    showFrontpage: {
      name: "Show Frontpage",
      validation: { required: false },
      dataType: "boolean",
    },
    showHeader: {
      name: "Show Header",
      validation: { required: false },
      dataType: "boolean",
    },
  },
});

const pagesCollection = buildCollection<pages>({
  name: "Pages",
  singularName: "Page",
  path: "pages",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  properties: {
    slug: {
      name: "Slug",
      validation: { required: true, unique: true },
      dataType: "string",
    },
    categoryId: buildProperty({
      dataType: "reference",
      path: "categories",
      validation: { required: true },
      name: "Category",
    }),
    content: buildProperty({
      dataType: "string",
      name: "Content",
      multiline: true,
      markdown: true,
      validation: { required: true },
    }),
    title: {
      name: "Title",
      description: "Not mandatory but it'd be awesome if you filled this up",
      dataType: "string",
      validation: { required: true, unique: true },
      columnWidth: 300,
    },
    enabled: {
      name: "Enabled",
      validation: { required: false },
      dataType: "boolean",
    },
    imageURL: buildProperty({
      name: "Image",
      dataType: "string",
      storage: {
        storagePath: "page_images",
        acceptedFiles: ["image/*"],
      },
    }),
    links: {
      dataType: "array",
      name: "Links",
      description: "Links",
      of: {
        dataType: "reference",
        path: "links",
      },
    },
    tags: {
      dataType: "array",
      name: "Tags",
      description: "Tags",
      of: {
        dataType: "reference",
        path: "tags",
      },
    },
  },
});

const linksCollection = buildCollection<links>({
  name: "Links",
  singularName: "Link",
  path: "links",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  properties: {
    url: {
      name: "URL",
      validation: { required: true, unique: true },
      dataType: "string",
    },
    name: {
      name: "Name",
      validation: { required: true, unique: true },
      dataType: "string",
    },
  },
});

const tagsCollection = buildCollection<tags>({
  name: "Tags",
  singularName: "Tag",
  path: "tags",
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  properties: {
    tag: {
      name: "Tag",
      validation: { required: true, unique: true },
      dataType: "string",
    },
  },
});

export default function App() {
  const myAuthenticator: Authenticator<FirebaseUser> = useCallback(async ({ user, authController }) => {
    if (user !== null && user.email != null && adminEmails.includes(user.email)) {
      console.log("Allowing access to", user?.email);
      const userRoles = await Promise.resolve(["admin"]);
      authController.setExtra(userRoles);
    } else {
      throw Error("Access Denied!");
    }
    return true;
  }, []);

  return <FirebaseCMSApp name={"InterPegasus"} authentication={myAuthenticator} collections={[appsCollection, categoriesCollection, pagesCollection, tagsCollection, linksCollection]} firebaseConfig={firebaseConfig} />;
}
